// @ts-strict-ignore
import React, { useEffect, useState } from "react"

import { PageProps } from "gatsby"

import { parseErrorMessage } from "api/errors"

import ShiftDetails from "components/admin-shifts/shift-details"
import Container from "components/container"
import { DriveLayout } from "components/layout-custom"

import { ShiftDetails as ShiftDetailsType } from "types/shift-detail"

import { fetchFromAPIBase } from "utils/fetch-utils"

const Page = (props: PageProps) => {
  const shiftId = props.params.shiftId

  const [shift, setShift] = useState<ShiftDetailsType>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>(null)

  async function fetchShift() {
    fetchFromAPIBase({
      path: `/v1/shifts/${shiftId}/`,
      authRequired: true,
    }).subscribe((response) => {
      if (response.error) {
        setError(parseErrorMessage(response))
      } else {
        setShift(response)
      }
      setLoading(false)
    })
  }

  useEffect(() => {
    fetchShift()
  }, [])

  return (
    <DriveLayout title="Shift" padded={true}>
      <Container>
        <ShiftDetails
          key={shift?.id ?? 0}
          _shift={shift}
          loadingShift={loading}
          errorLoadingShift={error}
          _refreshShift={fetchShift}
          audience="driver"
        />
      </Container>
    </DriveLayout>
  )
}

export default Page
